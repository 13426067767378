import { GeoLocation } from "@common/geoLocation";
import { BasicConfig, configure } from "@mind-foundry/mf-ui-core";

import { envVarBoolean, envVarNumeric, envVarString } from "./env";

const appName = "Mind Foundry Peregrine";
const appId = "peregrine";

const productCode = "prg";
const analyticsEventGroup = `${productCode}-group`;

const translationFiles = import.meta.glob("./messages/**/*.json", { eager: true, import: "default" });

export interface PhotoQuality {
  readonly blurThreshold: number;
  readonly darknessThreshold: number;
  readonly glareThreshold: number;
  readonly brightnessThreshold: number;
}

export interface Config extends BasicConfig {
  readonly analyticsEventGroup: string;
  readonly defaultGeoLocation: GeoLocation;
  readonly photoQuality: PhotoQuality;
  readonly gmaps: { readonly key: string };
  readonly featureFlags: {
    readonly excelExportAITab: boolean;
    readonly excelExportAssetTabs: boolean;
  };
  readonly i18n: BasicConfig["i18n"] & { readonly defaultLanguage: string };
}

const config: Config = {
  version: envVarString("MF_VERSION"),
  analyticsEventGroup,
  analytics: {
    enableAnalytics: envVarBoolean("MF_ANALYTICS_ENABLED"),
    mixpanelToken: envVarString("MF_ANALYTICS_TOKEN"),
    appId,
    appName,
  },
  auth: {
    clientId: envVarString("MF_AUTH_CLIENT_ID"),
    domain: envVarString("MF_AUTH_DOMAIN"),
    audience: envVarString("MF_AUTH_AUDIENCE"),
  },
  i18n: {
    languageDebug: envVarBoolean("MF_LANGUAGE_DEBUG"),
    defaultLanguage: envVarString("MF_DEFAULT_USER_LANGUAGE"),
    approvedLanguages: ["en", "ja"],
    groups: ["app", "house", "admin"],
    formatters: {},
    translationBundle: (p: string) => translationFiles[`./messages/${p}`],
  },
  support: {
    account: envVarString("MF_CUSTOMER_ACCOUNT_ID"),
    accountName: envVarString("MF_CUSTOMER_ACCOUNT_NAME"),
    platformHomeDomain: envVarString("MF_HOME_DOMAIN"),
    productCodename: appId,
  },
  photoQuality: {
    blurThreshold: envVarNumeric("MF_PHOTO_QUALITY_BLUR_THRESHOLD"),
    darknessThreshold: envVarNumeric("MF_PHOTO_QUALITY_DARKNESS_THRESHOLD"),
    glareThreshold: envVarNumeric("MF_PHOTO_QUALITY_GLARE_THRESHOLD"),
    brightnessThreshold: envVarNumeric("MF_PHOTO_QUALITY_BRIGHTNESS_THRESHOLD"),
  },
  defaultGeoLocation: {
    latitude: envVarNumeric("MF_DEFAULT_GEOLOCATION_LATITUDE"),
    longitude: envVarNumeric("MF_DEFAULT_GEOLOCATION_LONGITUDE"),
  },
  gmaps: {
    key: envVarString("MF_GMAPS_API_KEY"),
  },
  featureFlags: {
    excelExportAITab: envVarBoolean("MF_FEATFLAG_EXCEL_EXPORT_AI_TAB"),
    excelExportAssetTabs: envVarBoolean("MF_FEATFLAG_EXCEL_EXPORT_ASSET_TABS"),
  },
};

// Call configure to create a context provider, and reader hook
const { ConfigProvider, useConfig } = configure<Config>(config);
export { ConfigProvider };
export { useConfig };
