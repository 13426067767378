import { AuthErrorPage } from "@bridgePages/ErrorPage";
import { useGetCurrentUser } from "@common/requests/user";
import { AvailableRoutes } from "@common/routes";
import { useConfig } from "@config";
import { Spinner } from "@houseComponents/Spinner";
import { isBridgeRole, Role } from "@models/assets";
import { APIProvider } from "@vis.gl/react-google-maps";
import React, { useEffect, useMemo, useState } from "react";

export function App(): React.ReactElement {
  const config = useConfig();

  return (
    <APIProvider apiKey={config.gmaps.key}>
      <AppSelector />
    </APIProvider>
  );
}

export function AppSelector(): React.ReactElement {
  const { data: user, error } = useGetCurrentUser();
  const [ChosenApp, setChosenApp] = useState<
    React.ComponentType<{ readonly routes?: ReadonlyArray<AvailableRoutes> }> | null
  >(null);

  const availableRoutes = useMemo(() => {
    if (user?.role === Role.BRIDGE_INSPECTOR) {
      return [
        "home",
        "inspections",
        "inspectionPage",
        "annotationPage",
        "boundingBox",
        "newPhoto",
        "more",
        "language",
      ] as ReadonlyArray<AvailableRoutes>;
    }

    if (user?.role === Role.BRIDGE_ENGINEER) {
      return [
        "home",
        "inspectionPage",
        "annotationPage",
        "refineOverlay",
        "manualOverlay",
        "newAsset",
        "editAsset",
        "newPhoto",
        "more",
        "language",
      ] as ReadonlyArray<AvailableRoutes>;
    }

    return undefined;
  }, [user]);

  // Used to dynamically load either the house or bridge app based on the user's role
  // Prevents css clashes, improves performance and reduces bundle size
  useEffect(() => {
    if (isBridgeRole(user?.role)) {
      type BridgeAppProps = React.ComponentType<{ readonly routes?: ReadonlyArray<AvailableRoutes> }>;

      import("./modules/bridge/BridgeApp")
        .then((module) => setChosenApp(() => module.BridgeApp as BridgeAppProps))
        .catch((e) => {
          throw e;
        });
    } else if (user?.role === Role.HOUSE_POLICYHOLDER) {
      import("./modules/houseMobile/HouseMobileApp")
        .then((module: { readonly HouseMobileApp: React.ComponentType }) => setChosenApp(() => module.HouseMobileApp))
        .catch((e) => {
          throw e;
        });
    } else if (user?.role && [Role.HOUSE_SUPERVISOR, Role.HOUSE_REVIEWER].includes(user?.role)) {
      import("./modules/house/HouseApp")
        .then((module: { readonly HouseApp: React.ComponentType }) => setChosenApp(() => module.HouseApp))
        .catch((e) => {
          throw e;
        });
    } else if (user?.role === Role.ADMIN) {
      import("./modules/admin/AdminApp")
        .then((module) => setChosenApp(() => module.AdminApp))
        .catch((e) => {
          throw e;
        });
    }
  }, [user]);

  if (error) {
    return <AuthErrorPage />;
  }
  if (!user || !ChosenApp) {
    return <Spinner spinnerSize="large" />;
  }

  return <ChosenApp routes={availableRoutes} />;
}
