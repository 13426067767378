import { updateApp } from "@common/updateApp";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonPage,
  IonText,
} from "@ionic/react";
import { useAuth, useTranslation } from "@mind-foundry/mf-ui-core";
import { alertCircleOutline } from "ionicons/icons";
import React from "react";
import styled from "styled-components";

const ErrorContent = styled(IonContent) `
  .error-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 15vh;
    gap: 48px;
    align-items: center;
  }

  .header {
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .text {
    text-align: center;
  }

  .error-icon {
    font-size: 8em;
  }

  .button-container {
    display: flex;
  }

  .action-button {
    margin: 20px;
  }
`;

interface Props {
  readonly errorMessage?: string;
}

export function ErrorPage({ errorMessage }: Props): React.ReactElement {
  const { t } = useTranslation();
  const { logout } = useAuth();

  return (
    <IonPage>
      <ErrorContent class="ion-padding">
        <IonCard className="error-container">
          <IonCardHeader>
            <IonCardTitle className="header">
              <h1>{t("app:ERROR_PAGE_HEADER")}</h1>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="content">
            <IonIcon className="error-icon" icon={alertCircleOutline} />
            <IonText className="text">
              <h2>
                {t("app:ERROR_PAGE_GUIDANCE")}
              </h2>
            </IonText>
            {errorMessage && (
              <IonText className="text">
                <h1>
                  {errorMessage}
                </h1>
              </IonText>
            )}
            <div className="button-container">
              <IonButton
                className="action-button"
                onClick={logout}
              >
                {t("app:LOGOUT")}
              </IonButton>
              <IonButton
                className="action-button"
                onClick={updateApp}
              >
                {t("app:UPDATE_APP")}
              </IonButton>
            </div>
          </IonCardContent>
        </IonCard>
      </ErrorContent>
    </IonPage>
  );
}

export function AuthErrorPage(): React.ReactElement {
  const { t } = useTranslation();

  return <ErrorPage errorMessage={t("app:AUTH_FAILED")} />;
}
