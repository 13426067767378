type EnvVarsSource = Record<string, string>;

declare global {
  interface Window {
    readonly config: EnvVarsSource;
  }
}

const getEnvVarsSource = (): EnvVarsSource => {
  const isProd = process.env.NODE_ENV === "production";
  if (isProd) {
    // See index.html
    return filterOutPlaceholderValues(window.config);
  }
  return removeVitePrefix(import.meta.env as EnvVarsSource);
};

const removeVitePrefix = (source: EnvVarsSource): EnvVarsSource => (
  Object.fromEntries(
    Object.entries(source)
      .map(([key, value]) => [key.replace("VITE_", ""), value]),
  )
);

const filterOutPlaceholderValues = (source: EnvVarsSource): EnvVarsSource => (
  Object.fromEntries(
    Object.entries(source)
      .filter(([key, value]) => value !== `$${key}`),
  )
);

const envVars = getEnvVarsSource();

export const envVarString = (key: string): string => envVars[key]!;

export const envVarBoolean = (key: string): boolean => envVarString(key) === "true";

export const envVarNumeric = (key: string): number => Number(envVarString(key));
