import React, { useMemo } from "react";
import styled from "styled-components";

type SpinnerSize = "x-small" | "small" | "medium" | "large" | "x-large";

type SpinnerProps = React.HTMLProps<HTMLDivElement> & {
  readonly isFullPage?: boolean;
  readonly spinnerSize?: SpinnerSize;
  readonly cover?: boolean;
  readonly inline?: boolean;
  readonly className?: string;
};

export function Spinner({
  isFullPage = false,
  spinnerSize = "medium",
  cover = false,
  inline = false,
  ...props
}: SpinnerProps): React.ReactElement {
  const propsWithoutClassName = useMemo(() => {
    return Object.fromEntries(
      Object.entries(props).filter(([key]) => key !== "className"),
    );
  }, [props]);

  return (
    <SpinnerContainer
      className={`
        ${isFullPage ? "full-page-loader" : "component-loader"}
        ${spinnerSize}
        ${cover ? "cover" : ""}
        ${inline ? "inline" : ""}
        ${props.className || ""}
      `}
      {...propsWithoutClassName}
    >
      <div className="spinner" />
    </SpinnerContainer>
  );
}

const SpinnerContainer = styled.div `
  &.full-page-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
  }

  &.component-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &.inline {
      width: auto;
    }

    &.cover {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: inherit;
      border-radius: inherit;
      z-index: 1;
    }
  }

  .spinner {
    position: relative;
    top: -7.5%;
    border: var(--spinner-width) solid rgba(0, 0, 0, 0.1);
    border-top-color: color-mix(in srgb, currentColor 50%, transparent);
    border-radius: 50%;
    animation: spin 0.85s linear infinite;
  }

  &.x-small .spinner {
    --spinner-width: 2px;

    width: 18px;
    height: 18px;
  }

  &.small .spinner {
    --spinner-width: 4px;

    width: 40px;
    height: 40px;
  }

  &.medium .spinner {
    --spinner-width: 6px;

    width: 60px;
    height: 60px;
  }

  &.large .spinner {
    --spinner-width: 8px;

    width: 100px;
    height: 100px;
  }

  &.x-large .spinner {
    --spinner-width: 10px;

    width: 120px;
    height: 120px;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
