import { useSnackbar } from "notistack";
import { useCallback } from "react";

interface UseNotificationsHook {
  readonly showSuccess: (message: string) => void;
  readonly showError: (message: string) => void;
}

export const useNotifications = (): UseNotificationsHook => {
  const { enqueueSnackbar } = useSnackbar();

  const showError = useCallback((message: string): void => {
    enqueueSnackbar(message, { variant: "error" });
  }, [enqueueSnackbar]);

  const showSuccess = useCallback((message: string): void => {
    enqueueSnackbar(message, { variant: "success" });
  }, [enqueueSnackbar]);

  return { showSuccess, showError };
};
