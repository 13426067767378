import { useNotifications } from "@common/notifications";
import { useAuth, useTranslation } from "@mind-foundry/mf-ui-core";
import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from "axios";
import axiosRetry, { exponentialDelay, isNetworkError } from "axios-retry";
import { useCallback } from "react";

interface ErrorResponse {
  readonly detail?: unknown;
}

export type ProgressUpdate = (fractionCompleted: number) => void;

export type OnError = (error: AxiosError, returnedError?: string) => void;

export interface SuccessResponse {
  readonly success: boolean;
}

const getErrorMessage = (error: AxiosError): string | undefined => {
  if (error instanceof AxiosError) {
    let response = error.response?.data as ErrorResponse;

    if (response instanceof ArrayBuffer) {
      const decoder = new TextDecoder();
      const responseText = decoder.decode(response);

      response = JSON.parse(responseText) as ErrorResponse;
    }

    if (!response) {
      return response;
    }
    if (typeof response.detail === "string") {
      return response.detail;
    }

    return JSON.stringify(response.detail || response);
  }

  return undefined;
};

interface ErrorHandlerArgs {
  readonly error: AxiosError | unknown;
  readonly onError?: OnError;
  readonly errorMessage?: string;
  readonly errorProps?: Record<string, string>;
}

type ErrorHandler = (args: ErrorHandlerArgs) => unknown;

const useWrapOnError = () => {
  const { t } = useTranslation();
  const { showError } = useNotifications();

  return useCallback(({
    error,
    onError,
    errorMessage = "app:API_ERROR_UNKNOWN",
    errorProps = {},
  }: ErrorHandlerArgs) => {
    if (error instanceof AxiosError) {
      const returnedError = getErrorMessage(error);

      showError(returnedError ? String(returnedError) : t(errorMessage, errorProps));

      if (onError) {
        onError(error, returnedError);
      }
    }

    return error;
  }, [t, showError]);
};

function getBaseURL() {
  if (process.env.NODE_ENV === "development") {
    return import.meta.env.VITE_MF_BACKEND_DEV_URL as string ?? "http://localhost:8000/api/v1/";
  }
  return "/api/v1/";
}

export interface HttpHook {
  readonly http: AxiosInstance;
  readonly errorHandler: ErrorHandler;
}

export const useHttp = (): HttpHook => {
  const { getAccessToken } = useAuth();
  const errorHandler = useWrapOnError();

  const axiosInstance = axios.create({
    // process.env.NODE_ENV is set to "development" when the frontend is served by running "npm start"
    baseURL: getBaseURL(),
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const accessToken = await getAccessToken();

      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    },
  );

  axiosRetry(axiosInstance, {
    retryDelay: exponentialDelay,
    retryCondition: (error: AxiosError) => isNetworkError(error) || error.response?.status === 408,
  });

  return { http: axiosInstance, errorHandler };
};
