import { useHttp } from "@common/requests/http";
import { AppUser } from "@models/assets";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";

export const useGetCurrentUser = (): UseQueryResult<AppUser> => {
  const { http, errorHandler } = useHttp();

  return useQuery(["useGetCurrentUser"], async () => {
    const response = await http.get<AppUser>("/user/current").catch((error: AxiosError) => {
      errorHandler({
        error,
        errorMessage: "app:API_ERROR_GET_CURRENT_USER",
      });

      throw error;
    });

    return response.data;
  });
};
